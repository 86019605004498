<template>
  <div class="block">
    <span class="demonstration">
      Periodo
    </span>
    <el-date-picker
      v-model="period"
      type="daterange"
      align="bottom"
      start-placeholder="Fecha de Inicio"
      end-placeholder="Fecha de Cierre">
    </el-date-picker>
    <br>
    <el-checkbox-group v-model="sendAll">
      <el-checkbox label="Enviar a todos"></el-checkbox>
    </el-checkbox-group>

    <br>
    <br>
    <el-button type="success" @click="sendReport" :loading="sending">Enviar</el-button>
  </div>
</template>
<script>
export default {
  name: 'UVLatam',
  data () {
    return {
      period: null,
      sendAll: [],
      sending: false
    }
  },
  methods: {
    async sendReport () {
      this.sending = true

      try {
        console.log(this.period, this.sendAll)
        const dateSince = this.period[0].toISOString().replace(',', 'T').split('T')[0]
        const dateUntil = this.period[1].toISOString().replace(',', 'T').split('T')[0]
        const ready = (this.sendAll.includes('Enviar a todos')) ? 'OK' : 'None'

        const data = {
          since: `${dateSince}T04:00:00.000Z`,
          until: `${dateUntil}T03:59:59.999Z`,
          ready: ready
        }

        console.log(data)

        const response = await this.$http.post(
          'k8s/report/run',
          data
        )

        console.log('Response', response)

        this.$message.success('Revise su correo en un momento se enviará')
      } catch (error) {
        console.log(error)
        this.$message.error('Error no se puedo enviar el informe')
      } finally {
        this.sending = false
      }
    }
  }
}
</script>
